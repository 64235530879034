<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Blog Listesi</h3>
      </div>
      <div class="card-body tab-pane active table-responsive border-top userprof-tab">
        <table class="table table-bordered table-hover mb-0">
          <thead>
            <tr>
              <th>Kapak</th>
              <th>Başlık</th>
              <th>Tarih</th>
              <th>Durum</th>
              <th>İşlem</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in blogList" :key="i">
              <td>
                <div class="mt-0 mb-0">
                  <router-link :to="'/blogs/update/' + item.id"></router-link>
                  <img
                    width="200"
                    :src="'https://storage.terapivitrini.com/' + item.thumbnail"
                    alt="img"
                  />
                </div>
              </td>
              <td class="font-weight-semibold fs-16">{{ item.title }}</td>
              <td class="font-weight-semibold fs-16">{{ item.created_at }}</td>
              <td>
                <span class="badge badge-success" v-if="item.publish == 1"
                  >Yayınlandı</span
                >
                <span class="badge badge-danger" v-if="item.publish == 0"
                  >Taslak</span
                >
                <span class="badge badge-warning" v-if="item.publish == 2"
                  >Onaya Gönderildi</span
                >
              </td>
              <td>
                <router-link
                  :to="'/profile/blogs/update/' + item.id"
                  class="btn btn-primary text-white m-1"
                  data-bs-toggle="tooltip"
                  title="Güncelle"
                  data-bs-original-title="View"
                  ><i class="fa fa-pencil"></i
                ></router-link>
                <button
                  v-if="item.publish == 0"
                  @click="approvedBlog(item.id, i)"
                  class="btn btn-primary text-white m-1"
                  data-bs-toggle="tooltip"
                  title="Onaya Gönder"
                  data-bs-original-title="View"
                >
                  <i class="fa fa-check"></i>
                </button>
                <button
                  v-if="item.publish == 0"
                  @click="removeBlog(item.id, i)"
                  class="btn btn-primary text-white m-1"
                  data-bs-toggle="tooltip"
                  title="Sil"
                  data-bs-original-title="View"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  <script>
import Swal from "sweetalert2";
export default {
  created() {
    document.title = "Blog Listesi";
    if (this.$route.params.page) {
      this.currentpage = this.$route.params.page;
    }
    let educationInfo = {
      begin: (this.currentpage - 1) * this.perpage,
      perpage: this.perpage,
    };
    this.$store.dispatch("profileBlogList", educationInfo).then((value) => {
      this.blogList = value.list;
      this.totalsize = value.size;
      this.pagesize = value.pages;
      this.educationLoad = false;
    });
  },
  data() {
    return {
      blogList: [],
      currentpage: 1,
      perpage: 8,
      totalsize: 0,
      pagesize: 0,
      educationLoad: true,
    };
  },
  methods: {
    removeBlog(id, index) {
      Swal.fire({
        title: "Makaleyi silmek istediğinize emin misin?",
        text: "Bu işlem sonrası makaleniz silinecek, devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("blogRemove", { id: id }).then((value) => {
            if (value.type == "success") {
              this.$vToastify.success(
                "Makale başarılı bir şekilde silindi",
                "Uyarı!"
              );
              this.blogList.splice(index, 1);
            }
            if (value.type == "error") {
              this.$vToastify.success(
                "Onaylı makale silinemez",
                "Uyarı!"
              );
            }
          });
        }
      });
    },
    approvedBlog(id, index) {
      Swal.fire({
        title: "Makaleyi onaya göndermek istediğine emin misin?",
        text: "Bu işlem sonrası yapılan makale onaya gönderilecek. Onay sürecinde makalede değişiklik yapamazsınız, devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("approvedBlog", { id: id }).then((value) => {
            this.$vToastify.success(
              "Makale başarılı bir şekilde onaya gönderildi",
              "Uyarı!"
            );
            this.blogList[index].publish = 2;
          });
        }
      });
    },
  },
  components: {},
  mounted() {},
};
</script>